import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStoreService {
  private ls = window.localStorage;

  /* eslint-disable @typescript-eslint/no-explicit-any */
  public setItem(key: string, value: any) {
    value = JSON.stringify(value);
    this.ls.setItem(key, value);
    return true;
  }

  public getItem(key: string) {
    /* eslint-disable @typescript-eslint/typedef */
    const value = this.ls.getItem(key) as string;
    try {
      const parsedValue = JSON.parse(value);
      return parsedValue;
    } catch (e) {
      return null;
    }
  }

  public clear() {
    this.ls.clear();
  }
}
