import { Component, effect, inject, isDevMode } from '@angular/core';
import { GeneralStore } from '@root/store/general.store';
import LogRocket from 'logrocket';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  readonly generalStore = inject(GeneralStore);
  isLoaded: boolean = false;
  constructor() {
    if (!isDevMode()) {
      LogRocket.init('uzclgt/katanaprod');
    }

    effect(() => {
      this.isLoaded = this.generalStore.isLoaded();
    });
  }
  title = 'webapp';
}
