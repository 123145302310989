import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { CurrentUser, CurrentUserResponse, LocationCred, UserInfo, UserProfile } from '@root/models/user.model';
import { inject } from '@angular/core';
import { UserService } from '../services/user-service/user.service';
import { Location } from '@angular/common';
import { AssignedLocation } from './location.store';
import { Observable, Subject } from 'rxjs';
import { GlobalPermissions } from '@root/models/global-enum';
import { TaskItemType } from '@root/models/task-item.model';
import { FaviconService } from "@services/favicon.service";

/* eslint-disable @typescript-eslint/typedef */

export type GeneralState = {
  isLoaded: boolean;
  sidebarExpanded?: boolean;
  selectedLocationId?: number;
  allowedMaxFileUploadMb: string;
  assignedLocations: AssignedLocation[];
  isTaskBoardUser: boolean;
  userInfo: UserInfo;
  isLoggedIn?: boolean;
  globalCreds: GlobalPermissions[];
  locationCreds: Map<number, number[]>;
  loadedNotifier?: Observable<GeneralState>;
  profiles: UserProfile[];
  taskItemTypes: TaskItemType[];
  logoUrl?: string;
  iconUrl?: string;
  refreshingTokenSubject: Subject<void>;
};
export const GeneralStore = signalStore(
  { providedIn: 'root' },
  withState<GeneralState>({
    isLoaded: false,
    sidebarExpanded: true,
    selectedLocationId: null,
    allowedMaxFileUploadMb: '',
    assignedLocations: [] as AssignedLocation[],
    isTaskBoardUser: false,
    userInfo: null,
    isLoggedIn: false,
    globalCreds: [] as number[],
    locationCreds: new Map<number, number[]>(),
    loadedNotifier: null,
    profiles: [] as UserProfile[],
    taskItemTypes: [] as TaskItemType[],
    logoUrl: null,
    iconUrl: null,
    refreshingTokenSubject: null
  }),
  withMethods(
    (
      store,
      userService = inject(UserService),
      faviconService = inject(FaviconService),
    ) => ({
      setSidebarExpanded(isExpanded: boolean): void {
        patchState(store, { sidebarExpanded: isExpanded });
      },
      setRefreshingToken(refreshing: boolean): void {
        if (refreshing) {
          patchState(store, { refreshingTokenSubject: new Subject<void>() })
        }
        else {
          store.refreshingTokenSubject().next();
          store.refreshingTokenSubject().complete();
          patchState(store, { refreshingTokenSubject: null })
        }
      },
      selectLocation(locationId: number): void {
        patchState(store, { selectedLocationId: locationId });
      },
      deselectLocation(): void {
        patchState(store, { selectedLocationId: null });
      },
      reset(): void {
        patchState(store, {
          isLoaded: false,
          sidebarExpanded: true,
          selectedLocationId: null,
          allowedMaxFileUploadMb: '',
          assignedLocations: [] as AssignedLocation[],
          isTaskBoardUser: false,
          userInfo: null,
          isLoggedIn: false,
          globalCreds: [] as number[],
          locationCreds: new Map<number, number[]>(),
          loadedNotifier: null,
          profiles: [] as UserProfile[]
        });
      },
      getCurrentUser(): void {
        if (!store.loadedNotifier()) {
          const loadedObservable: Observable<GeneralState> = new Observable((subscriber) => {
            userService
              .getCurrentUser()
              .subscribe((res: CurrentUserResponse) => {
                
                const user: CurrentUser = res.data;
                const localCreds: Map<number, number[]> = new Map<number, number[]>();
                res.data.locationCreds.forEach((cred: LocationCred) => {
                  if (cred.allowed) {
                    let creds = localCreds.get(cred.locationID);
                    if (creds) {
                      creds.push(cred.credentialID);
                    }
                    else {
                      creds = [cred.credentialID];
                      localCreds.set(cred.locationID, creds);
                    }
                  }
                })

                const permissionsArray: GlobalPermissions[] = []
                if (user.globalCreds?.global_admin) {
                  permissionsArray.push(GlobalPermissions.GLOBAL_ADMIN);
                }
                if (user.globalCreds?.global_viewer) {
                  permissionsArray.push(GlobalPermissions.GLOBAL_VIEWER);
                }
                if (user.globalCreds?.report_admin) {
                  permissionsArray.push(GlobalPermissions.REPORT_ADMIN);
                }
                if (user.globalCreds?.report_viewer) {
                  permissionsArray.push(GlobalPermissions.REPORT_VIEWER);
                }

                const newState: GeneralState = {
                  allowedMaxFileUploadMb: user.allowedMaxFileUploadMb,
                  assignedLocations: user.assignedLocations,
                  isTaskBoardUser: user.isTaskBoardUser,
                  userInfo: user.userInfo,
                  isLoaded: true,
                  locationCreds: localCreds,
                  globalCreds: permissionsArray,
                  profiles: user.profiles,
                  taskItemTypes: user.taskItemTypes,
                  logoUrl: user.logoUrl || './assets/terlumina-logo.svg',
                  iconUrl: user.iconUrl || 'favicon.ico',
                  refreshingTokenSubject: null
                };

                patchState(store, newState);
                subscriber.next(newState);
                subscriber.complete();
                patchState(store, {loadedNotifier: null});
                faviconService.setFavicon(store.iconUrl());
            });
          });
          patchState(store, {loadedNotifier: loadedObservable});
        }
      },
      setCurrentLogoUrl(logoUrl: string) {
        patchState(store, { logoUrl: logoUrl });
      },
      setCurrentIconUrl(iconUrl: string) {
        patchState(store, { iconUrl: iconUrl });
      }
    }),
  ),

  withHooks((store, location: Location = inject(Location)) => ({
    onInit() {
      const currentUrl = location.path();
      if (!currentUrl.includes('login')) {
        store.getCurrentUser();
      }
    },
  })),
);
