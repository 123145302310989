import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { UserService } from '@root/services/user-service/user.service';
import { GeneralStore } from '@root/store/general.store';
import { environment } from 'src/environments/environment';
import { CustomerInfo, SSO_OPTIONS } from 'src/models/user.model';
import { FaviconService } from "@services/favicon.service";

@Component({
  selector: 'app-auth-buttons',
  templateUrl: './auth-buttons.component.html',
  styleUrls: ['./auth-buttons.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class AuthButtonsComponent {
  public customerInfo: CustomerInfo;
  public disableSSO: boolean = false;
  public inProgressAzure: boolean = false;
  public inProgressOkta: boolean = false;
  readonly generalStore = inject(GeneralStore);
  public showOktaLogin: boolean = false;
  public showAzureLogin: boolean = false;
  public faviconUrl:string = 'favicon.ico';

  constructor(private apiService: UserService, private faviconService: FaviconService) {
    this.apiService.getCustomerInfo().subscribe((res: CustomerInfo) => {
      this.customerInfo = res;
       if(this.customerInfo.logoUrl) {
        this.generalStore.setCurrentLogoUrl(this.customerInfo.logoUrl);
       } else {
        this.generalStore.setCurrentLogoUrl('./assets/terlumina-logo.svg');
       }
       if(this.customerInfo.iconUrl) {
         this.faviconUrl = this.customerInfo.iconUrl;
       }
       this.generalStore.setCurrentIconUrl(this.faviconUrl);
      this.faviconService.setFavicon(this.faviconUrl);
        res.ssoLogins.forEach((login) => {
        if (login === SSO_OPTIONS.OKTA) this.showOktaLogin = true;
        if (login === SSO_OPTIONS.AZURE) this.showAzureLogin = true;
      });
    });
  }

  public readonly loginWithSSO = (provider: string): void => {
    this.disableSSO = true;
    if (provider == 'azure') this.inProgressAzure = true;
    else if (provider == 'okta') this.inProgressOkta = true;
    window.location.href = `${environment.apiUrl}auth/${provider}/redirect`;
  };
}
