@if (this.currentUser) {
  <mat-sidenav-container class="sidebar-container" autosize>
    <mat-sidenav
      #sidenav
      mode="side"
      opened="true"
      class="side-nav"
      [ngClass]="{ open: isExpanded }"
      [disableClose]="true"
    >
      <div class="app-header-logo-container">
        <button class="expand-menu-button" mat-icon-button (click)="toggleSidebar()">
          <ng-icon name="heroBars3"></ng-icon>
        </button>
        @if (isExpanded) {
          <img class="logo" [ngSrc]="generalStore.logoUrl()"alt="Logo" height="23" width="157" priority/>
        }
      </div>
      <mat-nav-list class="nav-list">
        @if (isGlobalAdmin || isGlobalViewer) {
          <mat-list-item
            (click)="activeCall('/global/dashboard')"
            [routerLink]="'/global/dashboard'"
            (click)="showSubmenu = !showSubmenu"
            [class.active]="path.indexOf('/global/dashboard') >= 0"
            class="parent"
          >
            <div class="list-item">
              <mat-icon mat-list-icon>
                <ng-icon name="heroGlobeAmericas"></ng-icon>
              </mat-icon>
              @if (isExpanded || isShowing) {
                <span class="full-width">Global Dashboard</span>
              }
            </div>
          </mat-list-item>
        }

        @if (locations?.length) {
          <mat-list-item
            (click)="activeCall('/locations')"
            [ngClass]="{ active: path | regexMatch: '^\/locations(\/|$)' }"
            class="parent"
          >
            <div class="list-item">
              <mat-icon mat-list-icon>
                <ng-icon name="heroHome"></ng-icon>
              </mat-icon>
  
              @if (isExpanded || isShowing) {
                <span class="full-width">Locations</span>
                <mat-icon
                  class="menu-button"
                  [ngClass]="{ rotated: submenuState.locations }"
                >
                  <ng-icon class="expand-more" name="heroChevronRight"></ng-icon>
                </mat-icon>
              }
            </div>
          </mat-list-item>
        }

        @if (locations && path && (isShowing || isExpanded)) {
          <mat-nav-list
            class="submenu"
            [ngClass]="{
              expanded: submenuState.locations,
              'un-expand': !submenuState.locations
            }"
          >
            @for (location of locations; track location.locationID) {
              <mat-list-item
                (click)="activeCall('/locations/' + location.locationID)"
                [ngClass]="{
                  active: path.indexOf('/locations/' + location.locationID + '/') >= 0
                }"
                class="location"
              >
                <div class="list-item locations">
                  <span class="full-width ellipsis location-name">{{
                    location.locationName
                  }}</span>
                  <mat-icon
                    class="menu-button"
                    [ngClass]="{ rotated: selectedLocation === location }"
                  >
                    <ng-icon
                      class="expand-more"
                      name="heroChevronRight"
                    ></ng-icon>
                  </mat-icon>
                </div>
              </mat-list-item>
              @if (selectedLocation === location) {
                <mat-nav-list
                  class="submenu locations-items"
                  [ngClass]="{
                    expanded:
                      submenuState.locations && submenuState.selectedLocation,
                    'un-expand':
                      !submenuState.locations || !submenuState.selectedLocation
                  }"
                >
                  @for (
                    locationItem of locationSubmenuItems;
                    track locationItem.id
                  ) {
                    @if (locationItem.hasPermission) {
                      <mat-list-item
                        (click)="activeCall(locationItem.routerLink)"
                        class="location-item"
                        [ngClass]="{
                          active: path.indexOf(locationItem.routerLink) >= 0
                        }"
                        [routerLink]="locationItem.routerLink"
                        class="sub-section"
                      >
                        <div class="list-item">
                          <span class="full-width">{{
                            locationItem.displayText
                          }}</span>
                        </div>
                      </mat-list-item>
                    }
                  }
                </mat-nav-list>
              }
            }
          </mat-nav-list>
        }

        @if (isGlobalAdmin || isGlobalViewer || isReportAdmin || isReportViewer) {
          <mat-list-item
            (click)="activeCall('/global/reports')"
            [routerLink]="'/global/reports'"
            (click)="showSubmenu = !showSubmenu"
            [class.active]="path.indexOf('global/reports') >= 0"
            class="parent"
          >
            <div class="list-item">
              <mat-icon mat-list-icon>
                <ng-icon name="heroChartBarSquare"></ng-icon>
              </mat-icon>
              @if (isExpanded || isShowing) {
                <span class="full-width">Reports</span>
              }
            </div>
          </mat-list-item>
        }

        @if (isGlobalAdmin || isGlobalViewer) {
          <mat-list-item
            (click)="activeCall('/global/templates')"
            [routerLink]="'/global/templates'"
            [ngClass]="{ active: path.indexOf('global/templates') >= 0 }"
            class="parent"
          >
            <div class="list-item">
              <mat-icon mat-list-icon>
                <ng-icon name="heroRectangleGroup"></ng-icon>
              </mat-icon>

              @if (isExpanded || isShowing) {
                <span class="full-width">Global Templates</span>
              }
            </div>
          </mat-list-item>
        }

        @if (isGlobalAdmin || isGlobalViewer) {
          <mat-list-item
            (click)="activeCall('/global/references')"
            [routerLink]="'/global/references'"
            [ngClass]="{ active: path.indexOf('global/references') >= 0 }"
            class="parent"
          >
            <div class="list-item">
              <mat-icon mat-list-icon>
                <ng-icon name="heroDocument"></ng-icon>
              </mat-icon>

              @if (isExpanded || isShowing) {
                <span class="full-width">Global References</span>
              }
            </div>
          </mat-list-item>
        }

        @if (isGlobalAdmin || isGlobalViewer) {
          <mat-list-item
            (click)="activeCall('global/users-and-roles')"
            [ngClass]="{ active: path.indexOf('global/users-and-roles') >= 0 }"
            class="parent"
          >
            <div class="list-item">
              <mat-icon mat-list-icon>
                <ng-icon name="heroUserGroup"></ng-icon>
              </mat-icon>

              @if (isExpanded || isShowing) {
                <span class="full-width">Global Users & Roles</span>
              }

              @if (isExpanded || isShowing) {
                <mat-icon
                  class="menu-button"
                  [ngClass]="{ rotated: submenuState.userAndRole }"
                >
                  <ng-icon
                    class="expand-more"
                    name="heroChevronRight"
                  ></ng-icon>
                </mat-icon>
              }
            </div>
          </mat-list-item>
        }

        @if (isShowing || isExpanded) {
          <mat-nav-list
            class="submenu"
            [ngClass]="{
              expanded: submenuState.userAndRole,
              'un-expand': !submenuState.userAndRole
            }"
          >
            @if (isGlobalAdmin || isGlobalViewer) {
              <mat-list-item
                (click)="activeCall('/global/users-and-roles/users')"
                [ngClass]="{ active: path === '/global/users-and-roles/users' }"
                [routerLink]="'/global/users-and-roles/users'"
                class="sub-section"
              >
                <div class="list-item">
                  @if (isExpanded || isShowing) {
                    <span class="full-width">Users</span>
                  }
                </div>
              </mat-list-item>
            }

            @if (isGlobalAdmin || isGlobalViewer) {
              <mat-list-item
                (click)="activeCall('/global/users-and-roles/roles')"
                [ngClass]="{ active: path === '/global/users-and-roles/roles' }"
                [routerLink]="'/global/users-and-roles/roles'"
                class="sub-section"
              >
                <div class="list-item">
                  @if (isExpanded || isShowing) {
                    <span class="full-width">Roles</span>
                  }
                </div>
              </mat-list-item>
            }
          </mat-nav-list>
        }

        @if (isGlobalAdmin || isGlobalViewer) {
          <mat-list-item
            (click)="activeCall('/locations-and-categories')"
            (click)="showSubmenu = !showSubmenu"
            [ngClass]="{ active: path === '/locations-and-categories' }"
            class="parent"
            [routerLink]="'/locations-and-categories'"
          >
            <div class="list-item">
              <mat-icon mat-list-icon>
                <ng-icon name="heroSquaresPlus"></ng-icon>
              </mat-icon>

              @if (isExpanded || isShowing) {
                <span class="full-width">Locations & Categories</span>
              }
            </div>
          </mat-list-item>
        }
      </mat-nav-list>
    </mat-sidenav>
    <app-header></app-header>
    <div class="sidenav-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-container>
}
