import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { NewActionButtonComponent } from 'src/app/shared/components/new-action-button/new-action-button.component';
import { Subscription, throwError } from 'rxjs';
import { UserLoginParams } from '@models/user.model';
import { AuthButtonsComponent } from '@shared/components/auth-buttons/auth-buttons.component';
import { InputErrorComponent } from 'src/app/shared/components/input-error/input-error.component';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIconsModule } from '@ng-icons/core';
import { UserService } from '@root/services/user-service/user.service';
import { tapResponse } from '@ngrx/operators';
import { JwtAuthService } from '@root/services/auth/jwt-auth.service';
import { GeneralState, GeneralStore } from '@root/store/general.store';
import { GlobalPermissions, LocalPermissions } from '@root/models/global-enum';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NewActionButtonComponent,
    MatButtonModule,
    AuthButtonsComponent,
    InputErrorComponent,
    NgIconsModule,
    NgOptimizedImage,
    MatInputModule
  ],
})
export class LoginComponent implements OnDestroy, OnInit {
  errorSub: Subscription | undefined;
  errorMessage = '';
  loginForm: FormGroup;
  forgotPassword: FormGroup;
  forgotPasswordMsg: boolean = false;
  showLogin: boolean = true;
  showResetPassMsg: boolean = false;
  readonly userService: UserService = inject(UserService);
  readonly generalStore = inject(GeneralStore);
  public isOffline: boolean = !navigator.onLine;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: JwtAuthService,
    private cdr: ChangeDetectorRef
  ) {
    this.loginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });

    this.loginForm.valueChanges.subscribe(() => {
      this.errorMessage = '';
    });

    this.forgotPassword = new FormGroup({
      username: new FormControl('', Validators.required),
    });

    // TODO: make sure all errors are handled

    this.route.queryParams.pipe(takeUntilDestroyed()).subscribe((params) => {
      if (params && params['sso_login_fail']) {
        this.errorMessage = 'Login failed';
      }

      if (params && params['access_token']) {
        this.authService.setUserToken(params['access_token']);
        this.authService.setRefreshToken(params['refresh_token']);
        this.loginAction();
      }

      if (params && params['reset_pass']) {
        this.showResetPassMsg = true;
      }
    });
  }
  ngOnInit(): void {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  }


  private updateOnlineStatus = (): void => {
    this.isOffline = !navigator.onLine;
  };
  onSubmit() {
    if (this.loginForm.valid) {
      const loginPayload: UserLoginParams = {
        username: this.username?.value,
        password: this.password?.value,
      };

      this.userService
        .login(loginPayload)
        .subscribe(
          {
            next: () => {
              this.loginAction();
            },
            error: (error) => {
              this.errorMessage = 'Invalid login credentials. Please try again';
              return throwError(() => error)
            }
          });
    }
  }

  loginAction() {
    this.generalStore.getCurrentUser();
    this.generalStore.loadedNotifier().subscribe((newState: GeneralState) => {
      let redirectLink: string;
      if(newState.isTaskBoardUser) {
        redirectLink = `/locations/${newState.userInfo.userDefaultLocationID}/taskboard`;
      }
      else if (newState.globalCreds?.indexOf(GlobalPermissions.GLOBAL_ADMIN) > -1 ||
      newState.globalCreds?.indexOf(GlobalPermissions.GLOBAL_VIEWER) > -1) {
          if (newState.assignedLocations.length > 0) {
            redirectLink = '/global/dashboard';
          }
          else {
            redirectLink = '/global/no-locations';
          }
      }
      else if (newState.locationCreds.size > 0) {
        let foundLocation: boolean = false;
        newState.locationCreds.forEach((creds: number[], locationId: number) => {
          if ((creds.indexOf(LocalPermissions.LOCAL_ADMIN) > -1 ||
            creds.indexOf(LocalPermissions.LOCAL_VIEWER) > -1) && !foundLocation) {
            redirectLink = `/locations/${locationId}/local-dashboard`;
            foundLocation = true;
          }
        })
      }
      else if (newState.globalCreds?.indexOf(GlobalPermissions.REPORT_ADMIN) > -1 ||
      newState.globalCreds?.indexOf(GlobalPermissions.REPORT_VIEWER) > -1) {
        redirectLink = '/global/reports';
      }
      else if (newState.assignedLocations.length > 0) {
        redirectLink = `/locations/${newState.assignedLocations[0].locationID}/my-tasks`;
      }
      else {
        redirectLink = '/no-permissions';
      }
      if (redirectLink) {
        this.router.navigate([redirectLink]);
        this.cdr.detectChanges();
      }
    });
  }

  onSubmitForgetPassword() {
    // TODO: verify this flow is working as expected
    if (this.forgotPassword.valid) {
      this.userService
        .forgotPassword(this.forgotPasswordEmail?.value)
        .pipe(
          tapResponse({
            next: (_data) => {
              // TODO: do something with this data to determine if they need to re-route to login screen. Not sure what this obj looks like
            },
            error: (error) => {
              return throwError(() => error);
            },
          }),
        )
        .subscribe();
        this.forgotPasswordMsg = true;
        
    }
  }

  get username() {
    return this.loginForm.get('username');
  }

  get password() {
    return this.loginForm.get('password');
  }

  get forgotPasswordEmail() {
    return this.forgotPassword.get('username');
  }

  ngOnDestroy(): void {
    this.errorSub?.unsubscribe();
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  }

  goBack() {
    this.showLogin = true;
    this.forgotPasswordMsg = false;
    this.errorMessage = '';
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.showLogin = false;
      this.loginForm.reset();
    }
  }
}
