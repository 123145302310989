<app-dialog-header
  [title]="data.title"
  [dialogRef]="dialogRef"
></app-dialog-header>
<mat-dialog-content>
  @if (data.content) {
    @for (content of data.content; track content) {
      <div
        class="content-body"
        [class.scoped]="content.scope"
        [class.warning]="content.scope === 'warning'"
        [class.danger]="content.scope === 'danger'"
      >
        @if (content.scope) {
          <div class="scope-header">
            <ng-icon
              class="scope-icon"
              [name]="icons.get(content.scope)!"
            ></ng-icon>
            <span class="scope-title">{{ content.title }}</span>
          </div>
        }
        <div
          [class.scope-content]="content.scope"
          [innerHTML]="content.scope ? content.message : content"
        ></div>
      </div>
    }
      <div>
        @if(data.checkBox) {
          <mat-checkbox
          class="checkbox"
          [(ngModel)]="checked"
          ></mat-checkbox>
        }
        {{data.checkBox}}
      </div>
  }
</mat-dialog-content>
<div class="dialog-actions">
  <button class="cancel-button" (click)="close()">
    {{ data.cancelLabel }}
  </button>
    <button class="success-button" (click)="close(true)">
      {{ data.okLabel }}
    </button>
</div>
