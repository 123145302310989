import { Injectable, NgZone, inject } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, switchMap, take } from 'rxjs/operators';
import { NotificationService } from 'src/services/notification.service';
import { LocalStoreService } from 'src/services/local-store.service';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@root/services/user-service/user.service';
import { GeneralStore } from '@root/store/general.store';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  generalStore = inject(GeneralStore);
  constructor(
    private router: Router,
    private zone: NgZone,
    private notificationService: NotificationService,
    private authService: LocalStoreService,
    private dialogService: MatDialog,
    private userService: UserService,
  ) {}

  intercept(
    /* eslint-disable @typescript-eslint/no-explicit-any */
    req: HttpRequest<any>,
    /* eslint-disable @typescript-eslint/no-explicit-any */
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let waitingRefresh: boolean = false;
        if (error.status === 418) {
          console.error('418 error', error);
        }
        if (error.status === 401) {
          const token: string = this.authService.getItem('JWT_TOKEN');
          const refreshToken: string = this.authService.getItem('JWT_REFRESH_TOKEN');
          let tryRefresh: boolean = false;
          if (this.generalStore.refreshingTokenSubject()) {
            waitingRefresh = true;
            tryRefresh = false;
          }
          else if (!token && refreshToken) {
            tryRefresh = true;
          }
          else if (token && refreshToken) {
            const expiry: number = JSON.parse(atob(token.split('.')[1])).exp;
            tryRefresh = Math.floor(Date.now() / 1000) > expiry
          }

          if (tryRefresh) {
            this.generalStore.setRefreshingToken(true);
            this.authService.clear();
            return this.userService.refreshToken(refreshToken).pipe(
              switchMap(() => {
                const newAccessToken: string = this.authService.getItem('JWT_TOKEN');
                this.generalStore.setRefreshingToken(false);
                req = req.clone({
                  setHeaders: { Authorization: `Bearer ${newAccessToken}` },
                });
                return next.handle(req);
              }),
              catchError((error) => {
                this.zone.run(() => {
                  this.authService.clear();
                  this.router.navigate(['/login']);
                });
                return throwError(() => error);
              })
            )
          }
          else if (!waitingRefresh) {
            this.zone.run(() => {
              this.authService.clear();
              this.router.navigate(['/login']);
            });
          }
        }
        else if (error.status === 406) {
          this.zone.run(() => {
            this.authService.clear();
            this.router.navigate(['/login']);
          });
        } else if (error.status === 403) {
          // TODO: refresh current user in store
          this.dialogService.closeAll();
          // this.zone.run(() => {
          //   this.router.navigate(['/unauthorized']);
          // });
        } else {
          if (req.method === 'GET')
            this.notificationService.openErrorSnackBar(error.error?.message);
        }
        if (waitingRefresh) {
          const subject: Subject<HttpEvent<any>> = new Subject<HttpEvent<any>>();
          this.generalStore.refreshingTokenSubject().pipe(take(1)).subscribe(() => {
            const newAccessToken: string = this.authService.getItem('JWT_TOKEN');
            req = req.clone({
              setHeaders: { Authorization: `Bearer ${newAccessToken}` },
            });
            next.handle(req).subscribe((result: HttpEvent<any>) => {
              subject.next(result);
            })
          });
          return subject.asObservable();
        }
        else {
          return throwError(() => error);
        }
      }),
    );
  }
}
