<div class="login-wrapper">
  <div class="flex justify-center w-[525px]">
    <div class="container-box rounded-lg w-full bg-white flex justify-center">
      @if (showLogin) {
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="py-16">
          <div class="flex justify-center mb-8">
            @if(generalStore.logoUrl()) {
              <img class="logo" [ngSrc]="generalStore.logoUrl()" alt="Logo" height="32" width="218" priority/>
            }
          </div>
          
          @if (showResetPassMsg) {
            <div class="flex justify-center items-center mb-8 success-msg">
              <ng-icon name="heroCheckCircleSolid"></ng-icon>
              <span class="text-center success-msg-content"
              >Password reset successfully. Please login with your new
              password.</span
              >
            </div>
          }
          <div *ngIf="isOffline" class="offline-message">
            *You're offline please check your network settings.
          </div>

          <div
            class="form-control-wrapper"
            [ngClass]="{ 'invalid-field': username?.errors && username?.dirty }"
          >
          <label for="username" class="text-sm">Email</label>
            <input
              matInput
              type="text"
              id="username"
              class="form-control"
              formControlName="username"
            />
            <app-input-error
              [condition]="username?.dirty && username?.errors?.['required']"
              errorMessage="This field is required"
            >
            </app-input-error>
          </div>
          <div
            class="form-control-wrapper"
            [ngClass]="{ 'invalid-field': password?.errors && password?.dirty }"
          >
            <label for="password" class="text-sm">Password</label>
            <input
              matInput
              type="password"
              id="password"
              class="form-control"
              formControlName="password"
            />
            <app-input-error
              [condition]="password?.errors?.['required'] && password?.dirty"
              errorMessage="This field is required"
            >
            </app-input-error>
          </div>

          @if (errorMessage) {
            <div class="input-error">
              <app-input-error
                class="input-error mt-2"
                [errorMessage]="errorMessage"
              ></app-input-error>
            </div>
          }

          <div class="flex flex-col">
            <div class="w-full h-9 rounded-md mb-6">
              <app-new-action-button
                class="block"
                text="Sign In"
                [isDisabled]="loginForm.invalid"
                [fullWidth]="true"
              ></app-new-action-button>
            </div>
            <a
              class="forgot-pass hover:underline cursor-pointer float-left mb-8 mt-1"
              (keydown)="handleKeyDown($event)"
              (click)="showLogin = false; loginForm.reset()"
              tabindex="0"
            >
              Forgot password?
            </a>

            <app-auth-buttons></app-auth-buttons>
          </div>
        </form>
      }

      @if (!showLogin) {
        <form
          [formGroup]="forgotPassword"
          class="py-20"
        >
          <div class="back-and-logo mb-8">
            <ng-icon
              name="heroArrowLeftCircle"
              class="back cursor-pointer"
              (click)="goBack(); forgotPassword.reset()"
            ></ng-icon>
            <img class="logo" ngSrc="./assets/terlumina-logo.svg" alt="Logo" height="32" width="218" priority/>
            <div class="back"></div>
          </div>
          <div class="flex justify-center mb-8">
            <p class="reset-pass">Reset Your Password</p>
          </div>

          @if (!forgotPasswordMsg) {
            <div class="flex justify-center mb-8">
              <p class="reset-pass-desc text-center">
                Enter your email or username to receive a password reset link.
              </p>
            </div>
          } @else {
            <div class="flex justify-center items-center mb-12 success-msg">
              <ng-icon
                name="heroCheckCircleSolid"
                (click)="showLogin = true"
              ></ng-icon>
              <span class="text-center success-msg-content text-base"
                >If your username or email exists you will receive an email with
                instructions</span
              >
            </div>
          }

          <div class="flex flex-col gap-6">
            <div
              class="form-control-wrapper"
              [ngClass]="{
                'invalid-field':
                  forgotPasswordEmail?.errors && forgotPasswordEmail?.dirty
              }"
            >
              <input
                type="text"
                id="forgotPasswordEmail"
                class="form-control"
                placeholder="Email or Username"
                formControlName="username"
              />
              <app-input-error
                [condition]="
                  forgotPasswordEmail?.errors?.['required'] &&
                  forgotPasswordEmail?.dirty
                "
                errorMessage="This field is required"
              >
              </app-input-error>
            </div>
            <div class="w-full h-9 rounded-md mb-6">
              <app-new-action-button
                class="block"
                text="Reset Password"
                [isDisabled]="forgotPassword.invalid"
                (actionCompleted)="onSubmitForgetPassword()"
                [fullWidth]="true"
              ></app-new-action-button>
            </div>
          </div>
        </form>
      }
    </div>
  </div>
</div>
