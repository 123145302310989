@if (
  customerInfo &&
  (showAzureLogin || showOktaLogin)
) {
  <div class="sso-container">
    <div class="flex justify-center items-center mb-6">
      <span class="divider border-t block"></span>
      <span class="or mx-4">or</span>
      <span class="divider border-t block"></span>
    </div>
    @if (inProgressAzure || inProgressOkta) {
      <div class="text-center my-8">
        <span> Logging with SSO - please wait </span>
      </div>
    }
    @if (showAzureLogin) {
      <button
        [disabled]="disableSSO"
        [class.spinner]="inProgressAzure"
        (click)="$event.preventDefault(); loginWithSSO('azure')"
        class="btn btn-sso w-full h-9 rounded-md mb-4 flex justify-center items-center"
      >
        <img
          src="assets/img/microsoft-azure.png"
          alt="Microsoft azure login"
          class="w-auto mr-2"
        />
        <span>Continue with Azure</span>
      </button>
    }
    @if (showOktaLogin) {
      <button
        [disabled]="disableSSO"
        [class.spinner]="inProgressOkta"
        (click)="$event.preventDefault(); loginWithSSO('okta')"
        class="btn btn-sso w-full h-9 rounded-md flex justify-center items-center mr-4"
      >
        <img src="assets/img/okta-logo.svg" alt="Okta login" class="w-auto" />
        <span class="sign-in-with-sso">Sign In With Okta</span>
      </button>
    }
  </div>
}
