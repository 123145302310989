<h3 [class]="'snackbar-' + data.type + '-header'">
  @switch (data.type) { @case ('success') {
  <mat-icon mat-list-icon class="snack-bar-icon-wrapper">
    <ng-icon class="snack-bar-icon" name="heroCheckCircleSolid"></ng-icon>
  </mat-icon>
  } @case ('warning' || 'error') {
  <mat-icon mat-list-icon class="snack-bar-icon-wrapper">
    <ng-icon
      class="snack-bar-icon"
      name="heroExclamationTriangleSolid"
    ></ng-icon>
  </mat-icon>
  } } {{ data.type === "error" ? "Error:" : (data.type === "warning" ?
  "Warning:" : "") }} {{ data.type === "info" ? "Note: " : " " }}
</h3>
<p [class]="'snackbar-' + data.type + '-body'">{{ data.message }}</p>
