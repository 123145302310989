import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class FaviconService {
  constructor() {}

  setFavicon(iconUrl: string): void {
    const faviconElement:HTMLLinkElement = document.getElementById('appIcon') as HTMLLinkElement;
    if (faviconElement) {
      faviconElement.href = iconUrl;
    }
  }
}
