import { Category, CategoryMinified } from '@models/category.model';
import { Document, TableTag } from '@models/document.model';
import { ScopeType, TaskScope } from './global-enum';
import {
  ApiRequestParams,
  ApiResponse,
  defaultApiRequestParams,
} from './global-interfaces';
import { AssigneePreview, TaskUserPreview } from '@models/user.model';
import { ProfileSummary } from '@models/role.model';
import { AddTaskFilesPayload, TaskItem } from './task-item.model';
import { LocationMinified } from '@root/store/location.store';
import { TaskSetMinimum } from './task-set.model';

export const taskTableApiRequestParams: ApiRequestParams = {
  ...defaultApiRequestParams,
  dataScope: ScopeType.TaskTable,
};

export class TaskRequest {
  public id: number;
  public scope: TaskScope;
  public constructor(id: number, scope?: TaskScope) {
    this.id = id;
    if (scope) {
      this.scope = scope;
    }
  }
}

export interface ClosedTaskRequest {
  locationID: number;
  completedStartDate: string;
  completedEndDate: string;
  dueStartDate?: string;
  dueEndDate?: string;
}

export interface ClosedTaskResponse {
  taskID: number;
  topic: Category;
  dueAt: string;
  color: number;
  name: string;
  nameAddition: string;
  status: string;
  completedAt: string;
  completedBy: string;
  failedAt: string;
  failedReason: string;
  failedBy: TaskUserPreview;
  startDate: string;
  endDate: string;
 closedTasks: ClosedTask[]; 
 assignedTo: AssigneePreview;
 timezone_abbreviation: string;
}

export interface ClosedTask {
  taskID: number;
  topic: Category;
  dueAt: string;
  color: number;
  name: string;
  nameAddition: string;
  status: string;
  completedAt: string;
  completedBy: TaskUserPreview;
  failedAt: string;
  failedReason: string;
  failedBy: TaskUserPreview;
}

export interface TaskResponse<T> extends ApiResponse {
  data: T;
}

export interface TaskInfoResponse extends ApiResponse {
  data: TaskInfo;
}

export interface BaseTask {
  taskID: number;
  name?: string;
  nameAddition?: string;
  taskSetTemplateID?: number;
}

export interface TaskPreview extends BaseTask {
  approvalStatus: ApprovalStatus;
  assignedBy: string;
  assignedTo: AssigneePreview;
  color: number;
  created_at: string;
  default_assignee: AssigneePreview;
  deploymentStatus: boolean;
  dueDate: string;
  id: number;
  topic: Category;
  scope: TaskScope;
  delay: string;
}

export interface EventTaskInfoResponse {
  dueAt: string;
  data: {
    dueAt: string;
    taskID: number;
    profileID: number;
    timezone_abbreviation?: string;
  };
}

export interface eventResponse {
  data?: ApiResponse;
  dueAt: string;
  taskID: number;
  profileID: number;
}

export interface IncompleteTask {
  incomplete_tasks_id: number;
  task_id: number;
  name: string;
  topic_id: number;
  topics: Category[];
  due_at: Date;
  reason: string;
  incomplete_at: Date;
  assigned_to: string;
  default_assignee: AssigneePreview;
}

export interface TaskInfo extends BaseTask {
  allowAssessment: boolean;
  topic: Category;
  created_at?: string;
  shortName?: null;
  summary?: string;
  purpose?: string;
  builderComments?: string;
  dueAt?: null;
  assignedTo?: string;
  deploymentStatus?: boolean;
  createdBy?: string;
  isSpawned?: boolean;
  isDeployed?: boolean;
  isCompleted?: boolean;
  tags?: TableTag[];
  color: number;
  timezone_abbreviation?: string;
}

export interface TaskSetTask extends BaseTask {
  task_set_order: number;
  created_at: string;
  is_active: boolean;
  userID: number;
  profileID: number;
  dueAt: string;
  completedAt: string;
  color: number;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  default_assignee: any;
  isSpawned: boolean;
  isCompleted: boolean;
  isNative: boolean;
  isGlobal: boolean;
  isDeployed: boolean;
  isOpen: boolean;
  dueDateDisplay: string;
  hasScheduled: boolean;
  finalColor: number;
  hasAppendToTitleTaskItem: boolean;
  isLocalAddedTask: boolean;
  delay: string;
  locationID: number;
}

export interface OpenTaskSetTask {
  color: number;
  completedAt: string;
  created_at: string;
  default_assignee: AssigneePreview;
  dueAt: string;
  dueDateDisplay: string;
  emails: string[];
  finalClor: number;
  hasAppenedToTitleTaskItem: boolean;
  hasScheduled: boolean;
  isCompleted: boolean;
  isDeployed: boolean;
  isGlobal: boolean;
  isLocalAddedTask: boolean;
  isNative: boolean;
  isOpen: boolean;
  isSpawned: boolean;
  is_active: boolean;
  location: LocationMinified;
  locationID: number;
  name?: string;
  nameAddition?: string;
  profileID: number;
  taskID: number;
  task_set_order: number;
  userID: number;
  topic: Category;
  userIDs: number[];
}

export interface AssignTaskOption extends BaseTask {
  approvalStatus: ApprovalStatus;
  created_at: string;
  default_assignee: {
    profileDescription: string;
    created_at: string;
  };
  deploymentStatus: boolean;
  id: number;
  scope: string;
  topic: Category;
}

export interface TaskSettings extends BaseTask {
  allowEvents?: number;
  allowAssessment?: number;
  isDeployed?: boolean;
  is_active?: boolean;
  notifyOnAssignment?: number;
  notifyOnYellow?: number;
  notifyOnRed?: number;
  notifyOnFail?: number;
  task_reoccurs?: TaskReoccurs[];
  turnGreen?: number;
  turnRed?: number;
  turnYellow?: number;
  duration?: string;
  deployed_locations?: DeployedLocations[];
  locations?: LocationMinified[];
  topics?: CategoryMinified[];
  topic?: Category;
  task_documents: TaskDocument[];
  deploy_status: boolean;
  spawn_status: boolean;
  message?: string;
  roles?: ProfileSummary[];
  users?: TaskUserPreview[];
  defaultDueDate?: Date;
  dueAt?: string;
  locationID?: number;
  batchID?: number;
  default_assignee?: AssigneePreview;
  isLocalAddedTask: boolean;
  timezone_abbreviation?: string;
}

export interface EditTask extends BaseTask {
  taskItems: TaskItem[];
  taskSets: TaskSetMinimum[];
  topic: Category;
  topics: Category[];
  tags: TableTag[];
  availableTags: TableTag[];
  taskSet: TaskSetMinimum;
  roles: ProfileSummary[];
  profile: ProfileSummary;
  deploymentStatus: boolean;
  allowAssessment: boolean;
  shortName: string;
}

export interface TaskItemOption {
  itemID: number;
  itemOptionID: number;
  optionID: number;
  order: number;
  prompt: string;
  taskItemID: number;
  parentItemTypeId: number;
  indexLabel: string;
}

export interface WorkOnTask extends BaseTask {
  taskItems: TaskItem[] | [];
  documents: WorkOnTaskDocument[] | [];
  taskFiles: AddTaskFilesPayload[] | [];
  dueAt: Date;
  taskSummary: string;
  profileID: number;
  userID: number;
  allowAssessment: boolean;
  timezone_abbreviation?: string;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface TaskAutocompleteObject extends BaseTask {
  [key: string]: any;
}

export interface TaskReoccurs {
  reoccurID?: number;
  type?: number;
  startAt?: string;
  field1?: number;
  field2?: string;
  field3?: string;
  field4?: string;
  dueAt?: string;
  name?: string;
  is_active?: number;
  reoccurAt?: string;
  failed_tasks_schedule?: {
    id?: number;
    reoccur_id?: number;
    duration?: number;
    new_tasks?: number;
    time_interval?: string;
  };
}

export interface TaskDocument {
  taskDocumentsID?: number;
  taskID?: number;
  documentID: number;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  document?: Document;
}

export enum ApprovalStatus {
  Incomplete = 'incomplete',
  Complete = 'complete',
  Audited = 'audited',
  Approved = 'approved',
  Deployed = 'deployed',
  None = 'none',
}

export interface NewTaskTemplates extends ApiResponse {
  data: BaseTask;
}

export interface CreateTaskRequest {
  locationID?: number;
  isLocalAddedTask?: boolean;
}
export interface TaskUpdatePayload {
  taskID?: number;
  taskSetID?: number;
  failedAt?: string;
  failedBy?: number;
  failedReason?: string;
  [key: string]: BaseTask[keyof BaseTask];
}

export interface TaskUpdateTags {
  taskID?: number;
  tags: number[];
  id?: number;
}

export interface SpawnTaskPayload {
  locationID: number;
  profileID?: number;
  taskID?: number;
  itemID?: number;
  timestamp: string;
  userID?: number;
  timezone_abbreviation?: string;
}

export interface DuplicateTaskRequest {
  name: string;
  locationID?: number[];
  taskID: number;
}

export interface TaskDeploymentPayload {
  assignmentOption: number;
  locationIDS: number[];
  notificationOption: number;
  openTasksOption: number;
  scheduleOption: boolean;
  taskID?: number;
}
export interface RecallDeploymentPayload {
  taskID: number;
  locationID: number;
  openTasksOption: boolean;
}

export interface UnlinkTaskPayload {
  taskID: number;
}
export interface DeployedLocations {
  locationID: number;
  locationName: string;
  created_at: string;
}

export interface DeployLocation extends ApiResponse {
  data: BaseTask[];
}

export interface TaskTableRecord extends BaseTask {
  approvalStatus: ApprovalStatus;
  created_at: string;
  topic: Category;
  deploymentStatus: boolean;
  scope: TaskScope;
  dueDate: string;
  dueDateDisplay: string;
  color: number;
  // mutations
  topicName: string;
  default_assignee: AssigneePreview;
  assignedTo: AssigneePreview;
}

export const taskStatusTranslation: { [key: string]: string } = {
  incomplete: 'In Development',
  complete: 'Complete, Awaiting Audit',
  audited: 'Audited',
  approved: 'Ready to Deploy',
  deployed: 'Deployed',
  none: 'None',
};

export const taskStatusMap: Map<string, string | null> = new Map([
  ['In Development', 'incomplete'],
  ['Complete, Awaiting Audit', 'complete'],
  ['Audited', 'audited'],
  ['Ready to Deploy', 'approved'],
  ['Deployed', 'deployed'],
  ['None', null],
]);

export interface WorkOnTaskDocument {
  taskDocumentsID?: number;
  documentID: number;
  document: {
    documentID: number;
    documentFileName: string | undefined;
    documentScope: string | undefined;
    uploadFileName: string | undefined;
  };
}

export class DelayTaskRequest {
  taskID: number;
  reason: string;
  delayEndAt: string;
}

export class TaskReminderRequest {
  to: number[];
  subject: string;
  message: string;
  taskID: number;
}

export interface AssignableTaskRequest {
  locationID?: number;
}

export interface AssignableTask {
  isCompleted: boolean;
  isDeployed: boolean;
  isGlobal: boolean;
  isOpen: boolean;
  name: string;
  taskID: number;
}
