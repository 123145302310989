import {
  heroArrowRightOnRectangle,
  heroArrowLeftCircle,
  heroBars3,
  heroUser,
  heroUserGroup,
  heroCheck,
  heroChartPie,
  heroChartBarSquare,
  heroChevronDown,
  heroChevronLeft,
  heroChevronRight,
  heroChevronUp,
  heroChevronUpDown,
  heroDocument,
  heroEnvelope,
  heroEye,
  heroGlobeAmericas,
  heroHome,
  heroLockOpen,
  heroLockClosed,
  heroMagnifyingGlass,
  heroPlus,
  heroPlusSmall,
  heroRectangleGroup,
  heroSquaresPlus,
  heroTrash,
  heroUserMinus,
  heroUsers,
  heroXMark,
  heroDocumentArrowUp,
  heroDocumentCheck,
  heroBookOpen,
  heroCheckCircle,
  heroXCircle,
  heroPencilSquare,
  heroInformationCircle,
  heroLink,
  heroPlusCircle,
  heroArrowsRightLeft,
  heroArrowsPointingOut,
  heroCalendar,
  heroCalendarDays,
  heroListBullet,
  heroPaperClip,
  heroQueueList,
  heroTableCells,
  heroTag,
  heroUserCircle,
  heroDocumentDuplicate,
  heroMinusCircle,
  heroArrowTopRightOnSquare,
  heroNoSymbol,
  heroClock,
  heroClipboardDocument,
  heroBarsArrowDown,
  heroBarsArrowUp,
  heroChevronDoubleRight,
} from '@ng-icons/heroicons/outline';
import {
  heroLockClosedSolid,
  heroBellSolid,
  heroUserGroupSolid,
  heroExclamationTriangleSolid,
  heroExclamationCircleSolid,
  heroEyeSolid,
  heroQuestionMarkCircleSolid,
  heroTagSolid,
  heroUserSolid,
  heroUsersSolid,
  heroUserCircleSolid,
  heroLockOpenSolid,
  heroDocumentSolid,
  heroBookOpenSolid,
  heroCheckCircleSolid,
  heroGlobeAmericasSolid,
  heroXCircleSolid,
  heroLinkSolid,
  heroSquares2x2Solid,
  heroMapPinSolid,
  heroRectangleStackSolid,
  heroDocumentDuplicateSolid,
  heroArrowLongRightSolid,
  heroEllipsisHorizontalSolid,
  heroChartBarSquareSolid,
  heroArrowUturnLeftSolid,
  heroInformationCircleSolid,
  heroCalendarSolid,
  heroQueueListSolid,
  heroRectangleGroupSolid,
  heroTableCellsSolid,
  heroCog6ToothSolid,
  heroPlayCircleSolid,
  heroPlaySolid,
  heroForwardSolid,
  heroAdjustmentsHorizontalSolid,
  heroEnvelopeSolid,
  heroTrashSolid,
} from '@ng-icons/heroicons/solid';
import {
  heroChevronDownMini,
  heroChevronRightMini,
  heroChevronUpMini,
  heroHomeMini,
  heroMapPinMini,
} from '@ng-icons/heroicons/mini';

/* eslint-disable @typescript-eslint/typedef */
const breakLink =
  '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" style="width:var(--ng-icon__size; 1em);height:var(--ng-icon__size; 1em)"><g transform="matrix(1,0,0,1,0.115738,-0.0661982)"><path d="M12.616,10.316C12.616,10.316 14.168,10.624 14.113,12.066" style="fill:none;stroke:currentColor;stroke-width:var(--ng-icon__stroke-width, 1);stroke-linecap:round;stroke-linejoin:round;"/></g><g transform="matrix(1,0,0,1,0.115738,-0.0661982)"><path d="M11.426,9.394L13.044,7.739C13.044,7.739 14.72,6.122 16.262,7.663C18.31,9.712 15.989,11.393 15.989,11.393" style="fill:none;stroke:currentColor;stroke-width:var(--ng-icon__stroke-width, 1);stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"/></g><g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,24.1573,24.0305)"><path d="M12.616,10.316C12.616,10.316 14.168,10.624 14.113,12.066" style="fill:none;stroke:currentColor;stroke-width:var(--ng-icon__stroke-width, 1);stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"/></g><g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,24.1573,24.0305)"><path d="M11.426,9.394L13.044,7.739C13.044,7.739 14.72,6.122 16.262,7.663C18.31,9.712 15.989,11.393 15.989,11.393" style="fill:none;stroke:currentColor;stroke-width:var(--ng-icon__stroke-width, 1);stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"/></g><path d="M7.051,7.184L16.91,16.745" style="fill:none;stroke:currentColor;stroke-width:var(--ng-icon__stroke-width, 1);stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"/></svg>';
/* eslint-disable @typescript-eslint/typedef */

const reorder =
  '<svg viewBox="0 0 24 24" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" style="width:var(--ng-icon__size; 1em);height:var(--ng-icon__size; 1em);stroke-linecap:round;stroke-linejoin:round;"><path d="M9.522,6.02l-1.508,0c-2.631,0 -5.353,2.834 -5.353,6.225c0,3.391 2.661,6.181 5.044,6.181" style="fill:none;fill-rule:nonzero;stroke:currentColor;stroke-width:var(--ng-icon__stroke-width, 1);"/><path d="M10.417,6.028l-2.658,2.341l0,-4.682l2.658,2.341Z" style="stroke:currentColor;stroke-width:var(--ng-icon__stroke-width, 1);"/><path d="M20.806,18.424c0,-0.689 -0.446,-1.248 -0.996,-1.248l-5.392,-0c-0.55,-0 -0.996,0.559 -0.996,1.248l-0,0.004c-0,0.689 0.446,1.248 0.996,1.248l5.392,-0c0.55,-0 0.996,-0.559 0.996,-1.248l0,-0.004Z" style="stroke:currentColor;stroke-width:var(--ng-icon__stroke-width, 1);"/><path d="M20.806,12.221c0,-0.689 -0.441,-1.248 -0.984,-1.248l-7.429,0c-0.542,0 -0.983,0.559 -0.983,1.248l-0,0.004c-0,0.689 0.441,1.248 0.983,1.248l7.429,0c0.543,0 0.984,-0.559 0.984,-1.248l0,-0.004Z" style="stroke:currentColor;stroke-width:var(--ng-icon__stroke-width, 1);"/><path d="M20.806,6.026c0,-0.689 -0.457,-1.248 -1.021,-1.248l-5.343,0c-0.563,0 -1.02,0.559 -1.02,1.248l-0,0.004c-0,0.689 0.457,1.248 1.02,1.248l5.343,0c0.564,0 1.021,-0.559 1.021,-1.248l0,-0.004Z" style="fill:none;stroke:currentColor;stroke-width:var(--ng-icon__stroke-width, 1);"/></svg>';

/* eslint-disable @typescript-eslint/typedef */
const checkbox =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width:var(--ng-icon__size, 1em);height:var(--ng-icon__size, 1em);fill:none;stroke:currentColor;stroke-width:var(--ng-icon__stroke-width, 1);stroke-linecap:round;stroke-linejoin:round;"><path d="M9 12.8L11.2 15L15 9.8M21 12C21 14.7 21 21 21 21C21 21 14.3 21 12 21C9.9 21 3 21 3 21C3 21 3 14.9 3 12C3 9.5 3 3 3 3C3 3 9.5 3 12 3C14.5 3 21 3 21 3C21 3 21 9.5 21 12Z"/></svg>';

export const Icons = {
  heroArrowRightOnRectangle,
  heroArrowLeftCircle,
  heroBellSolid,
  heroLockClosed,
  heroLockClosedSolid,
  heroBars3,
  heroUser,
  heroUserGroup,
  heroCheck,
  heroChartPie,
  heroChartBarSquare,
  heroChartBarSquareSolid,
  heroChevronDown,
  heroChevronLeft,
  heroChevronRight,
  heroChevronUp,
  heroChevronUpDown,
  heroClock,
  heroClipboardDocument,
  heroDocument,
  heroEnvelope,
  heroEnvelopeSolid,
  heroEye,
  heroEyeSolid,
  heroGlobeAmericas,
  heroGlobeAmericasSolid,
  heroHome,
  heroLockOpen,
  heroMagnifyingGlass,
  heroPlus,
  heroPlusSmall,
  heroRectangleGroup,
  heroSquaresPlus,
  heroTrash,
  heroTrashSolid,
  heroUserMinus,
  heroUsers,
  heroXMark,
  heroMinusCircle,
  heroUserGroupSolid,
  heroExclamationTriangleSolid,
  heroExclamationCircleSolid,
  heroQuestionMarkCircleSolid,
  heroTagSolid,
  heroUserSolid,
  heroUsersSolid,
  heroUserCircle,
  heroUserCircleSolid,
  heroDocumentSolid,
  heroLinkSolid,
  heroSquares2x2Solid,
  heroDocumentArrowUp,
  heroDocumentCheck,
  heroBookOpenSolid,
  heroBookOpen,
  heroLockOpenSolid,
  heroCheckCircleSolid,
  heroXCircleSolid,
  heroCheckCircle,
  heroPlaySolid,
  heroPlayCircleSolid,
  heroForwardSolid,
  heroXCircle,
  heroPencilSquare,
  heroChevronDownMini,
  heroChevronRightMini,
  heroChevronUpMini,
  heroHomeMini,
  heroInformationCircle,
  heroLink,
  heroMapPinSolid,
  heroMapPinMini,
  heroRectangleStackSolid,
  heroDocumentDuplicateSolid,
  heroPlusCircle,
  heroListBullet,
  heroEllipsisHorizontalSolid,
  heroQueueList,
  heroQueueListSolid,
  heroCalendarDays,
  heroTag,
  heroTableCells,
  heroTableCellsSolid,
  heroArrowsRightLeft,
  heroPaperClip,
  heroArrowLongRightSolid,
  heroCalendar,
  heroCalendarSolid,
  heroDocumentDuplicate,
  heroArrowUturnLeftSolid,
  heroInformationCircleSolid,
  breakLink,
  reorder,
  heroRectangleGroupSolid,
  checkbox,
  heroCog6ToothSolid,
  heroArrowTopRightOnSquare,
  heroNoSymbol,
  heroAdjustmentsHorizontalSolid,
  heroBarsArrowDown,
  heroBarsArrowUp,
  heroArrowsPointingOut,
  heroChevronDoubleRight,
};
