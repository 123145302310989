import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStoreService } from '../services/local-store.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private authService: LocalStoreService,
  ) {}
  intercept(
    /* eslint-disable @typescript-eslint/no-explicit-any */
    request: HttpRequest<any>,
    /* eslint-disable @typescript-eslint/no-explicit-any */
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token: string = this.authService.getItem('JWT_TOKEN');
    if (token && !request.url.includes('.s3.')) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });
    }
    return next.handle(request);
  }
}
